<template>
  <div class="row">
    <div class="form-group col-md-12 text-right">
      <div class="card-header pt-2 pb-2">
        <div class="row">
          <div class="col-md-11"></div>
          <div class="col-md-1">
            <div class="btn-group float-right">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                data-toggle="modal"
                data-target="#modal_tarifa_km"
                @click="getDatosModal()"
                v-if="$store.getters.can('tif.tarifarios.edit')"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body table-responsive p-0">
      <div class="row">
        <div class="col-md-12">
          <table
            class="table table-bordered table-striped table-hover text-nowrap table-sm"
            v-if="$parent.form"
          >
            <thead class="thead-dark">
              <tr>
                <th class="text-center">Operación</th>
                <th class="text-center">Descripción</th>
                <th class="text-center">Fecha Inicial</th>
                <th class="text-center">Fecha Final</th>
                <th class="text-center">Km Inicial</th>
                <th class="text-center">Km Final</th>
                <th class="text-center">Cant. Horas</th>
                <th class="text-center">Valor</th>
                <th
                  class="text-center"
                  style="width: 120px"
                  v-if="$parent.estado == 1"
                >
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="oper in listasForms.agroupOper" :key="oper.id">
                <td class="text-center">
                  <b>{{ oper.descripcion }}</b>
                </td>
                <td class="text-center">
                  <div
                    style="height: 30px"
                    v-for="list in oper.lista"
                    :key="list.id"
                  >
                    {{ list.descripcion }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    style="height: 30px"
                    v-for="list in oper.lista"
                    :key="list.id"
                  >
                    {{ list.fecha_ini }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    style="height: 30px"
                    v-for="list in oper.lista"
                    :key="list.id"
                  >
                    {{ list.fecha_fin }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    style="height: 30px"
                    v-for="list in oper.lista"
                    :key="list.id"
                  >
                    {{ list.km_ini }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    style="height: 30px"
                    v-for="list in oper.lista"
                    :key="list.id"
                  >
                    {{ list.km_fin }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    style="height: 30px"
                    v-for="list in oper.lista"
                    :key="list.id"
                  >
                    {{ list.cant_horas }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    style="height: 30px"
                    v-for="list in oper.lista"
                    :key="list.id"
                  >
                    {{ list.valorTarifa }}
                  </div>
                </td>
                <td v-if="$parent.estado == 1">
                  <div
                    style="height: 30px"
                    v-for="list in oper.lista"
                    :key="list.id"
                  >
                    <button
                      type="button"
                      class="btn btn-xs bg-navy"
                      data-toggle="modal"
                      data-target="#modal_tarifa_km"
                      @click="getDatosModal(list, oper)"
                      v-if="$store.getters.can('tif.tarifarios.edit')"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-xs btn-danger"
                      @click="destroy(list)"
                      v-if="$store.getters.can('tif.tarifarios.delete')"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal Asignación Tarifas Km -->
    <div class="modal fade" id="modal_tarifa_km">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Tarifa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-6">
                <label>Operación</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.operacion"
                  :class="
                    $v.form.operacion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="operacion in listasForms.operaciones"
                    :key="operacion.numeracion"
                    :value="operacion.numeracion"
                  >
                    {{ operacion.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Descripción</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="form.descripcion"
                  :class="
                    $v.form.descripcion.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3">
                <label>Fecha Inicio</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_ini"
                  :class="
                    $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Fecha Fin</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_fin"
                  :class="
                    $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFechas()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Km Inicial</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.km_ini"
                  :class="$v.form.km_ini.$invalid ? 'is-invalid' : 'is-valid'"
                  @blur="validarKm()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Km Final</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.km_fin"
                  :class="$v.form.km_fin.$invalid ? 'is-invalid' : 'is-valid'"
                  @blur="validarKm()"
                />
              </div>
              <div class="form-group col-md-3">
                <label>Cantidad Horas</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.cant_horas"
                  :class="
                    $v.form.cant_horas.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-3">
                <label>Valor Tarifa</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  min="0"
                  v-model="form.valor_tarifa"
                  :class="
                    $v.form.valor_tarifa.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('tif.tarifarios.edit') && !$v.form.$invalid
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TarifasKm",
  components: {},
  data() {
    return {
      cargando: false,
      bloque_id: null,
      act: 1,
      form: {
        id: null,
        tif_tarifario_id: this.$parent.form.id,
        operacion: null,
        descripcion: null,
        km_ini: null,
        km_fin: null,
        fecha_ini: null,
        fecha_fin: null,
        cant_horas: null,
        valor_tarifa: null,
      },
      listasForms: {
        operaciones: [],
        agroupOper: [],
      },
      datos_tarifa_historico: [],
      tarifa_historico: [],
    };
  },
  validations() {
    return {
      form: {
        operacion: {
          required,
        },
        descripcion: {
          required,
        },
        km_ini: {
          required,
        },
        km_fin: {
          required,
        },
        fecha_ini: {
          required,
        },
        fecha_fin: {
          required,
        },
        cant_horas: {
          required,
        },
        valor_tarifa: {
          required,
        },
      },
    };
  },
  methods: {
    init() {
      this.act = this.$route.params.act;
      this.bloque_id = this.$parent.form.bloque_id;
      this.getOperaciones();
      this.agrouparOp();
    },

    getOperaciones() {
      axios.get("/api/lista/120").then((response) => {
        this.listasForms.operaciones = response.data;
      });
    },

    agrouparOp() {
      let me = this;
      axios({
        method: "GET",
        url: "/api/tif/tarifarios/agroupOpera",
        params: me.form.tif_tarifario_id,
      }).then((response) => {
        me.listasForms.agroupOper = response.data;
        var agroupOper = Object.values(me.listasForms.agroupOper);        
        for (let i = 0; i < agroupOper.length; i++) {
          for (let j = 0; j < agroupOper[i].lista.length; j++) {
            let valor = agroupOper[i].lista[j].valor_tarifa;
            agroupOper[i].lista[j].valorTarifa = me.formatCurrency(
              "es-CO",
              "COP",
              2,
              valor
            );
          }
        }
      });
    },

    getDatosModal(tarifa = null, oper = null) {
      this.$parent.cargando = true;
      this.agrouparOp();
      this.resetForm();
      if (tarifa) {        
        this.form = {
          id: tarifa.id,
          tif_tarifario_id: this.$parent.form.id,
          operacion: oper.numeracion,
          descripcion: tarifa.descripcion,
          km_ini: tarifa.km_ini,
          km_fin: tarifa.km_fin,
          fecha_ini: tarifa.fecha_ini,
          fecha_fin: tarifa.fecha_fin,
          cant_horas: tarifa.cant_horas,
          valor_tarifa: tarifa.valor_tarifa,
        };
      }
      this.$parent.cargando = false;
    },

    resetForm() {
      this.form = {
        id: null,
        tif_tarifario_id: this.$parent.form.id,
        operacion: null,
        descripcion: null,
        km_ini: null,
        km_fin: null,
        fecha_ini: null,
        fecha_fin: null,
        cant_horas: null,
        valor_tarifa: null,
      };
    },

    validarKm() {
      if (this.form.km_ini && this.form.km_fin) {
        if (this.form.km_ini >= this.form.km_fin) {
          this.form.km_fin = null;
          this.$swal({
            icon: "error",
            title: `El rango de km inicial debe se menor que el rango de km final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      }
    },

    async validarFechas() {
      // Se valida que las fechas estén dentro de la vigencia del contrato
      await this.validaVigenciaContrato();
      // Se valida que la fecha inicial no sea mayor a la fecha final
      await this.validaFechasVigencia();
      // Si ya la tarifa ha sido creada se valida que la fecha inicial a crear so sea menor de la fecha final de la tarifa creada vigente
      if (this.form.id) {
        await this.validaFechasTarifa();
      }
    },

    validaVigenciaContrato() {
      const inicio_contrato = this.$parent.form.fecha_ini;
      const fin_contrato = this.$parent.form.fecha_fin;
      if (this.form.fecha_ini) {
        if (
          !this.validaFechas(inicio_contrato, this.form.fecha_ini) ||
          !this.validaFechas(this.form.fecha_ini, fin_contrato)
        ) {
          this.form.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_fin) {
        if (
          !this.validaFechas(inicio_contrato, this.form.fecha_fin) ||
          !this.validaFechas(this.form.fecha_fin, fin_contrato)
        ) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Fin de la Tarifa se encuentra fuera de las fechas estipuladas en el contrato...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechasVigencia() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        if (!this.validaFechas(this.form.fecha_ini, this.form.fecha_fin)) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa no puede ser mayor a la Fecha Fin...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechasTarifa() {
      if (this.form.fecha_ini) {
        const fecha_fin_det_tarifa = this.$parent.form.fecha_fin;
        if (!this.validaFechas(fecha_fin_det_tarifa, this.form.fecha_ini)) {
          this.form.fecha_ini = null;
          this.$swal({
            icon: "error",
            title: `La Fecha Inicio de la Tarifa se cruza con la vigencia de la tarifa actual, se debe poner una fecha posterior a ${fecha_fin_det_tarifa}...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechas(fecha_menor, fecha_mayor) {
      fecha_menor = new Date(fecha_menor);
      fecha_mayor = new Date(fecha_mayor);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        return false;
      }
      return true;
    },

    save() {
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/tif/tarifarios/tarifasKm",
          data: this.form,
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.$parent.form.tarifa_km = response.data.tarifas;
            this.$refs.closeModal.click();
            this.agrouparOp();
            this.$swal({
              icon: "success",
              title: "La tarifa se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.resetForm();
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(tarifa) {      
      this.$swal({
        title: "Está seguro de eliminar esta Tarifa?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .delete("/api/tif/tarifarios/tarifasKm/" + tarifa.id)
            .then((response) => {
              this.$parent.cargando = false;
              this.$parent.form.tarifa_km = response.data.tarifas;
              this.agrouparOp();
              this.$swal({
                icon: "success",
                title: "Se eliminó la Tarifa exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
